import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";

import { LoginComponent } from "./login/login.component";
import { CustomAuthComponent } from "./custom-auth/custom-auth.component";
import { CustomRequestPasswordComponent } from "./custom-request-password/custom-request-password.component";


export const routes: Routes = [
  {
    path: "",
    component: CustomAuthComponent,
    children: [
      {
        path: "",
        redirectTo: "login",
        pathMatch: "prefix"
      },
      {
        path: "login",
        component: LoginComponent,
      },
            {
        path: "register",
        component: NbRegisterComponent,
      },
      {
        path: "logout",
        component: NbLogoutComponent,
      },
      {
        path: "request-password",
        component: CustomRequestPasswordComponent,
      },
      {
        path: "reset-password",
        component: NbResetPasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthRoutingModule {}
