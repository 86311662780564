import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import * as moment from "moment";

import { environmentProtec3Api } from "../../../environments/environment"

import { TokenSignIn } from '../interfaces/protec3-api/bda/signIn-token.interface';
import { UserBDTR } from '../interfaces/protec3-api/bdtr/user.interface';
import { RequestPasswordSR, SuccessResponse } from '../interfaces/protec3-api/success-response.interface';
import { DeviceBDA } from '../interfaces/protec3-api/bda/device.interface';
import { DataObject } from '../interfaces/protec3-api/bdtr/data-object.interface';
import { TimeInterval } from '../interfaces/protec3-api/bdtr/time-interval.interface';
import { ClientBDTR } from '../interfaces/protec3-api/bdtr/client.interface';
import { Identifier } from '../interfaces/protec3-api/bdtr/identifier.interface';
import { DeviceProfileBDTR } from '../interfaces/protec3-api/bdtr/device-profile.interface';
import { EventBdtr, EventBdtrTable } from '../interfaces/protec3-api/bdtr/event-interface';


@Injectable({
  providedIn: 'root'
})
export class BdtrService {
  private url: string = `${environmentProtec3Api.urlHost}/bdtr`;

  private EVENT_ACTIVE_ALARM = {
    '1': "accelerometer",
    '2': "pir",
    '3': "tracker",
    '4': "button",
    '5': "camera"
  };

  constructor(private http: HttpClient, private router: Router) {}

  // Client
  getOneClient(client_code: string): Observable<ClientBDTR>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<ClientBDTR>(`${this.url}/client/${client_code}`, { headers });
  }

  // User
  signIn(username: string, password: string){
    return this.http.post<TokenSignIn>(`${this.url}/user/signIn`, {username, password});
  }

  getUserByUsername(usernameP: string): Observable<UserBDTR>{
    const { username, password } = environmentProtec3Api.admin_credentials;
    const headers = new HttpHeaders({ Authorization: "Basic " + btoa(username + ":" + password)});

    const params = new HttpParams().set("withAdmin", 1);

    return this.http.get<UserBDTR[]>(`${this.url}/user/signUp`, { headers, params })
      .pipe(
        map((result)=>{
          const user = result.find((value) => value.username == usernameP) || null;
          if(!user) {
            this.throwHttpError();
          }
          const { old_passwords, continuous_fails,
            can_try_at, expire_at, last_try,
            created_at, updated_at, ...restUser} = user;

          return restUser;
        })
      );
  }

  requestPassword(usernameP: string, newPassword: string) {
    const { username, password } = environmentProtec3Api.admin_credentials;
    const headers = new HttpHeaders({ Authorization: "Basic " + btoa(username + ":" + password)});

    return this.http.patch<RequestPasswordSR>(`${this.url}/user/signUp`, {username: usernameP, password: newPassword},  { headers });
  }

  // Device
  getAllClientDevices(client_code: string): Observable<DeviceBDA[]> {
    const token = JSON.parse(localStorage.getItem("auth_app_token"))?.value ?? {};
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/device/all/${client_code}`, { headers })
    .pipe(
      map((response)=> {
        const deviceBDA: DeviceBDA[] = response.data;
        return deviceBDA;
      }),
      catchError((err)=>{
        this.checkHttpError(err, false);
        throw new HttpErrorResponse(err);
      })
    );
  }

  // Device profile

  updateDeviceProfile(serial_number: string, profile: any): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.patch<SuccessResponse>(`${this.url}/device/profile/${serial_number}`, profile, { headers })
    .pipe(
      map((response)=>{
        return response.success;
      }),
      catchError((err)=> {
        console.error(err);
        return of(false);
      })
    );
  }

  getOneDeviceProfile(serial_number: string): Observable<DeviceProfileBDTR>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/device/profile/${serial_number}`, { headers })
    .pipe(
      map((reponse)=>{
        const deviceProfiles: DeviceProfileBDTR = reponse.data[0];
        return deviceProfiles;
      })
    )
  }

  getAllClientDeviceProfiles(client_code: string): Observable<DeviceProfileBDTR[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/device/profile/all/${client_code}`, { headers })
    .pipe(
      map((reponse)=>{
        const deviceProfiles: DeviceProfileBDTR[] = reponse.data;
        
        return deviceProfiles.map((deviceProfile)=>{
          return {
            ...deviceProfile,
            name: `${deviceProfile.name} ${deviceProfile.identifier}`
          }
        });
      })
    )
  }

  // Identifier (device)
  createIdentifier(identifier: Identifier): Observable<Identifier>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<Identifier>(`${this.url}/identifier`, identifier, { headers })
    .pipe(
      map((identifier)=>{
        identifier.created_at = moment(identifier.created_at).format('DD/MM/YYYY HH:mm:ss');
        identifier.updated_at = moment(identifier.updated_at).format('DD/MM/YYYY HH:mm:ss');

        return identifier;
      })
    );
  }

  // Location
  getAllDeviceLocations(serial_number: string, { from, to }: TimeInterval): Observable<DataObject[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token"))?.value ?? {};
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    // console.log(from, to)

    const params = new HttpParams()
    .set("from", from ? from : moment().subtract(8, "hours").toString()) // .subtract(2, "days")
    .set("to", to ? to : moment().toString()); // .add(2, "days")
    // console.log(params)
    // console.log(moment().subtract(8, "hours").toString())

    return this.http.get<SuccessResponse>(`${this.url}/location/${serial_number}`, { headers, params })
      .pipe(
        map((response)=>{
          const dataObjects: DataObject[] = response.data;
          return dataObjects;
        }),
        catchError((err)=>{
          this.checkHttpError(err);
          throw new HttpErrorResponse(err);
        })
        // catchError((error) => of(undefined))
        // catchError((error) => {
        //   console.error(error);
        //   return throwError(()=>{});
        // })
      );;
  }

  // Event
  getAllDeviceEvents(serial_number: string, timeInterval: TimeInterval): Observable<EventBdtrTable[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token"))?.value ?? {};
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    // console.log(from, to)

    const {from, to} = timeInterval;

    const params = new HttpParams()
    .set("from", from ? from : moment().subtract(30, "days").toString()) // .subtract(2, "days")
    .set("lines_per_page", 100)
    .set("order", "desc")
    // .set("to", to ? to : moment().toString()); // .add(2, "days")
    // console.log(params)
    // console.log(moment().subtract(8, "hours").toString())

    return this.http.get<SuccessResponse>(`${this.url}/event/${serial_number}`, { headers, params })
      .pipe(
        map((response)=>{
          const dataObjects: DataObject[] = response.data;
          let events: EventBdtrTable[] = []; 

          dataObjects.forEach((dataObject)=>{
            if(dataObject.identifier === "SN123" || dataObject.identifier === "SN675" ) return;

            let event: EventBdtr = dataObject.data;
            events.push({
              device: dataObject.identifier,
              client: dataObject.client,
              active_alarm: event?.reason ?? this.EVENT_ACTIVE_ALARM[event?.cause] ?? "",
              status: event?.status ?? "",
              rssi: event.rssi,
              key_actived: event.key,
              cops: event.cops,
              model: event?.model ?? "Protec3",
              battery_level: `${event.batt}V`,
              timestamp: dataObject.timestamp
            });
          });
          return events;
        }),
        catchError((err)=>{
          this.checkHttpError(err);
          throw new HttpErrorResponse(err);
        })
        // catchError((error) => of(undefined))
        // catchError((error) => {
        //   console.error(error);
        //   return throwError(()=>{});
        // })
      );
  }

  getAllClientEvents(client_code: string, timeInterval: TimeInterval): Observable<EventBdtrTable[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token"))?.value ?? {};
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    // console.log(from, to)

    const {from, to} = timeInterval;

    const params = new HttpParams()
    .set("from", from ? from : moment().subtract(30, "days").toString()) // .subtract(2, "days")
    .set("lines_per_page", 500)
    .set("order", "desc")
    // .set("to", to ? to : moment().toString()); // .add(2, "days")
    // console.log(params)
    // console.log(moment().subtract(8, "hours").toString())

    return this.http.get<SuccessResponse>(`${this.url}/event/all/${client_code}`, { headers, params })
      .pipe(
        map((response)=>{
          const dataObjects: DataObject[] = response.data;
          let events: EventBdtrTable[] = []; 

          dataObjects.forEach((dataObject)=>{
            if(dataObject.identifier === "SN123" || dataObject.identifier === "SN675" ) return;

            let event: EventBdtr = dataObject.data;
            events.push({
              device: dataObject.identifier,
              client: dataObject.client,
              active_alarm: event?.reason ?? this.EVENT_ACTIVE_ALARM[event?.cause] ?? "",
              status: event?.status ?? "",
              rssi: event.rssi,
              key_actived: event.key,
              cops: event.cops,
              model: event?.model ?? "Protec3",
              battery_level: `${event.batt}V`,
              timestamp: dataObject.timestamp
            });
          });
          return events;
        }),
        catchError((err)=>{
          this.checkHttpError(err);
          throw new HttpErrorResponse(err);
        })
      );
  }

  private logout(){
    localStorage.removeItem("auth_app_token");
    localStorage.removeItem("userActive");
    this.router.navigate(["/auth/login"]);
  }

  checkHttpError(err: any, showErr=true){
    if(err.status === 401 || !this.userActive) {
      this.logout();
    }else{
      if (!showErr) return;
      console.error(err);
    }
  }

  throwHttpError() {
    throw new HttpErrorResponse({
      status: 401,
      error: {
        errorStatus: 401,
        error: "Unauthorized",
        message: "Unauthorized: User doesn't exist or password is invalid: Check your username and your password!!"
      }
    });
  }

  get userActive(): UserBDTR{
    return JSON.parse(localStorage.getItem("userActive"));
  }
}
