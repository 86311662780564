import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserActive } from '../interfaces/user.interface';
import { Token } from '../interfaces/token.interface';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { environmentAuthApi } from '../../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private url: string = environmentAuthApi.urlHost;

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) {}

  getUserByEmail(email: string): Observable<UserActive>{
    return this.http.post<UserActive>(`${this.url}/user/email`, {email: email});
  }

  requestPassword(newPassword: string, email: string): Observable<any>{
    return this.http.put<any>(`${this.url}/user/request-password`, {newPassword: newPassword, email: email});
  }

  getUsers(): Observable<UserActive[]>{
    return this.http.get<UserActive[]>(`${this.url}/user`)
    .pipe(
      map((result)=>{
        result.forEach((user)=>{
          delete user.password;
        })
        return result;
      })
    );
  }

  deleteUserById(id: number): Observable<any>{
    return this.http.delete<any>(`${this.url}/user/${ id}`);
  }

  updateUserById(userU:UserActive){
    return this.http.put<any>(`${this.url}/user/update/${userU.id}`, userU);
  }

  signUp(user: UserActive): Observable<any>{
    return this.http.post<any>(`${this.url}/register`, user);
  }

  /* login(user: UserActive): Observable<Token> {
    return this.http.post<Token>(`${this.url}/login`, user);
  }

  isAuthorized(token: Token): Observable<any>{
    return this.http.post<any>(`${this.url}/auth`, { jwtA: token.token});
  }

  logout(){
    this.cookieService.delete('sessionCookie');
    this.router.navigateByUrl("/auth/login");
  } */
}
