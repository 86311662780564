import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { UserBDTR } from '../../../shared/interfaces/protec3-api/bdtr/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { PagesService } from '../../../pages/pages.service';

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  image: string = "assets/images/male_avatar.png";
  userActive: UserBDTR | null = null;
  profile: string = this.translateService.instant("header.profile_title");
  selectedLang: string = "english"

  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  languages = [
    {
      value: "english",
      option: "English",
      src: "assets/images/svg/flags/us.svg"
    },
    {
      value: "spanish",
      option: "Spanish",
      src: "assets/images/svg/flags/es.svg"
    }
  ]

  enlaceLogo = "assets/images/logos/protec3/protec3-logo.png";
  currentTheme = "default";

  userMenu = [
    // { title: this.translateService.instant("header.dropdown.go_profile_title") },
    { title: this.translateService.instant("header.dropdown.logout") }
  ]

  //!
  //userMenu = [];
  //!

  //!
  // private menuTranslated = new BehaviorSubject<any>(this.userMenu);
  // get userMenu$() {
  //   return this.menuTranslated.asObservable();
  // }
  //!

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private nbMenuService: NbMenuService,
    private router: Router,
    private translateService: TranslateService,
    private pagesService: PagesService
  ) {}

  get username(){
    return (this.userActive.username.split("@")[0] ?? this.userActive.username) + ` (${this.userActive?.client_name ?? ""})`;
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    //!
    // this.menuTranslated.next([
    //   { title: this.translateService.instant("header.dropdown.go_profile_title") },
    //   { title: this.translateService.instant("header.dropdown.logout") }
    // ])

    // this.userMenu$.subscribe((value)=>{
    //   this.userMenu = value;
    // })

    // this.translateService.onLangChange.subscribe(() => {
    //   this.menuTranslated.next([
    //     { title: this.translateService.instant("header.dropdown.go_profile_title") },
    //     { title: this.translateService.instant("header.dropdown.logout") }
    //   ])
    //   this.profile = this.translateService.instant("header.profile_title");
    // });
    //!

    /* this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick)); */

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
      /* console.log(this.user); */

      this.userActive= JSON.parse(localStorage.getItem('userActive'));

      // Llamada del observable onItemClick del servicio nbMenuService,
      // para gestionar la acción del menú desplegable del perfil
      this.nbMenuService.onItemClick()
      .pipe(
        /* filter(({ tag }) => tag === 'my-context-menu'), */
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        switch (title) {
          case this.translateService.instant("header.dropdown.go_profile_title"): {
            this.goToProfile();
            break;
          }
          case this.translateService.instant("header.dropdown.logout"):{
            this.logout();
            break;
          }
          default: {}
        }
      });

  }

  logout(){
    localStorage.removeItem('userActive');
    localStorage.removeItem('auth_app_token');
    this.router.navigateByUrl('/auth')
  }

  goToProfile(){

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    //console.log(themeName);
    this.themeService.changeTheme(themeName);
  }

  changeLanguage(lng: string){
    const languageSelect = {
      spanish: () => this.translateService.use("es"),
      english: () => this.translateService.use("en"),
    }
    languageSelect[lng]();
    // this.translateService.use("es")
  }

  cambiarLogo() {
    //console.log(this.currentTheme);

    switch (this.currentTheme) {
      case "default": {
        this.enlaceLogo = "assets/images/logos/protec3/protec3-logo.png"; // assets/images/logos/azul/logo10.png
        break;
      }
      case "dark": {
        this.enlaceLogo = "assets/images/logos/protec3/protec3-logo.png"; // assets/images/logos/blanco/logo10.png
        break;
      }
      case "cosmic": {
        this.enlaceLogo = "assets/images/logos/protec3/protec3-logo.png"; // assets/images/logos/blanco/logo10.png
        break;
      }
      case "corporate": {
        this.enlaceLogo = "assets/images/logos/protec3/protec3-logo.png"; // assets/images/logos/azul/logo10.png
        break;
      }
    }

  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    //this.router.navigate(["/pages/maps/leaflet"])
    return false;
  }

}
