<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a [routerLink]="['/pages/maps/leaflet']">
      <img [src]="enlaceLogo" alt="Logo_Empresa" id="logoBizDigital" >
    </a>                      <!--(click)="navigateHome()"" /pages/iot-dashboard-->
  </div>

 <!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value" (click)="cambiarLogo()">
      {{ theme.name }}
    </nb-option>
  </nb-select>
  <div></div> -->
</div>

<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <!-- <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action> -->
  <!-- !Selección de idioma-->
        <!-- <nb-action size="small">
          <nb-select placeholder="Select Showcase" [(selected)]="selectedLang" (selectedChange)="changeLanguage($event)">
            <nb-option *ngFor="let lng of languages; let i=index" [value]="lng.value">
               <span>{{lng.option}}</span>
            </nb-option>
          </nb-select>
        </nb-action> -->
  <!-- !Etiqueta de imagen responsive-->
  <!-- <div>
    <img [src]="lng.src" alt="{{lng.option}}" class="img-responsive rounded-4" width="20px" height="20px"
  </div> -->

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [name]="username"
               [picture]="image"> <!-- [name]="userActive.username | titlecase" entre los dos property binding debe estar este o profile-->
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
