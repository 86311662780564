import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartTableComponent } from './smart-table/smart-table.component';

@NgModule({
  imports: [
    CommonModule,
    SmartTableComponent,
  ],
  declarations: [],
  exports: [SmartTableComponent]
})
export class SharedModule { }
