import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { NbAuthService } from '@nebular/auth';
import { tap } from "rxjs/operators";

const authGuard = () => {
  const nbAuthService= inject(NbAuthService);
  const router = inject(Router)

  return nbAuthService.isAuthenticated().pipe(
    tap((result)=> {
      const userActive= localStorage.getItem('userActive') || null;

      if(userActive == null){
        localStorage.removeItem('auth_app_token');
        router.navigateByUrl('/auth/login');
        return;
      }

      if (!result){
        router.navigateByUrl('/auth/login');
        localStorage.removeItem('userActive');
        return;
      }

      // nbAuthService.getToken().subscribe((result)=> {
      //   nbAuthService.refreshToken('email', )

      // })
    })
  );
};

export const AuthGuardActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return authGuard();
}

export const AuthGuardChild: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return authGuard();
}
