import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbRequestPasswordComponent } from '@nebular/auth';
import { AuthService } from '../../shared/services/auth.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { BdtrService } from '../../shared/services/bdtr.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-custom-request-password',
  templateUrl: './custom-request-password.component.html',
  styles: [
  ]
})
export class CustomRequestPasswordComponent extends NbRequestPasswordComponent {
  emailIsCorrect: boolean = false;
  errorEmail: boolean = false;
  errorPassword: boolean = false;
  passwordRequest: boolean = false;
  formBuilder: FormBuilder = inject(FormBuilder);
  authService: AuthService = inject(AuthService);
  bdtrService: BdtrService = inject(BdtrService);
  translateService: TranslateService = inject(TranslateService);
  router: Router = inject(Router);

  formRequest: FormGroup = this.formBuilder.group({
    newPassword: ['', [Validators.required, Validators.minLength(6)]]
  });

  submitEmail(): void {
    this.bdtrService.getUserByUsername(this.user.email)
    .pipe(
      catchError((err)=>{
        this.emailIsCorrect = false;
        this.errorEmail = true;
        return throwError(err);
      })
    )
    .subscribe((result)=>{
      if(result.role === "admin"){
        this.emailIsCorrect = false;
        this.errorEmail = true;
        this.bdtrService.throwHttpError();
      }
      this.emailIsCorrect= true;
      this.errorEmail = false;
    })
  }

  override requestPass(){
    this.bdtrService.requestPassword(this.user.email, this.formRequest.controls['newPassword'].value)
    .pipe(
      catchError((err)=>{
        const errorMessage = this.translateService.instant("auth.request_password.error_message");
        this.errorPassword = true;
        //console.error(err);
        if(err.status === 400){
          this.errors = [`${errorMessage}: ['${this.translateService.instant("auth.request_password.policies_message1")}']`]
        }else{
          this.errors = [`${errorMessage}: ['${this.translateService.instant("auth.request_password.policies_message2")}]`];
        }
        return throwError(err);
      })
    )
    .subscribe((result)=>{
      this.passwordRequest = true;
      setTimeout(()=>{
        this.router.navigate(['/auth']);
      }, 1000);
    })
  }

  isValidField(field:string, error: string){
    return this.formRequest.controls[field].getError(error) != null && this.formRequest.touched;
  }

  get marginInput(){
    return this.formRequest.errors == null && !this.formRequest.touched || this.formRequest.value.length >= 6;
  }
}
