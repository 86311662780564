<nb-layout>
  <nb-layout-column>
    <nb-card>
      <!-- <nb-card-header>
        <nav class="navigation">
          <a href="#" (click)="back()" class="link" aria-label="Back"><i class="icon nb-arrow-thin-left"></i></a>
        </nav>
      </nb-card-header> -->
      <nb-card-body>
        <nb-auth-block>
          <div>
            <router-outlet></router-outlet>
          </div>
        </nb-auth-block>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>
