import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as moment from "moment";

import { environmentProtec3Api } from "./../../../environments/environment"

import { SuccessResponse } from '../interfaces/protec3-api/success-response.interface';
import { DeviceBDA } from '../interfaces/protec3-api/bda/device.interface';
import { UserBDA } from '../interfaces/protec3-api/bda/user.interface';
import { UserBDTR } from '../interfaces/protec3-api/bdtr/user.interface';
import { ContactBDA } from '../interfaces/protec3-api/bda/contact.interface';
import { GroupBDA } from '../interfaces/protec3-api/bda/group.interface';
import { GatewayBDA } from '../interfaces/protec3-api/bda/gateway.interface';
import { ClientBDA } from '../interfaces/protec3-api/bda/client.interface';


@Injectable({
  providedIn: 'root'
})
export class BdaService {
  private url: string = `${environmentProtec3Api.urlHost}/bda`;

  private TYPES_DEVICE = {
    0: "UNKNOWN",
    1: "PROTEC3",
  }

  private TYPES = {
    0: "TYPE 1",
    1: "TYPE 2",
    2: "TYPE 3"
  }

  private TYPES_USER = {
    0: "UNKNOWN",
    1: "DOMESTIC",
    2: "PROFESSIONAL",
    3: "BUSINESS"
  }

  private TYPES_GATEWAY = {
    0: "OTHER",
    1: "GATEWAY",
    2: "MOBILE",
    3: "BEACON"
  }

  private STATUS = {
    0: "ENABLED",
    1: "SUSPENDED",
    '-1': "DISABLED"
  }

  private MODE_GATEWAY = {
    0: "NONE",
    1: "ROUTER",
    2: "OTHER"
  }

  constructor(private http: HttpClient) { }

  // Client

  getOneClient(client_code: string): Observable<ClientBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/client/${client_code}`, { headers })
      .pipe(
        map((response) => {
          let clientBDA: ClientBDA = response.data[0];

          // Formateo de las fechas
          clientBDA.created_at = moment(clientBDA.created_at).format('DD/MM/YYYY HH:mm:ss');
          clientBDA.updated_at = moment(clientBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');
          if (clientBDA.deleted_at)
            clientBDA.deleted_at = moment(clientBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');

          // Traducción de los atributos type y status
          // userBDA.type = this.TYPES_USER[userBDA.type] || "";

          return clientBDA;
        })
      );
  }

  // Device

  getAllClientDevices(client_code: string): Observable<SuccessResponse>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/device/all/${client_code}`, { headers })
      .pipe(
        map((response)=> {
          response.data.map((value: DeviceBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if(value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type y status
            value.type = this.TYPES_DEVICE[value.type] || "";
            value.status = this.STATUS[value.status] || "";

            return value;
          })
          return response;
        })
      );
  }

  getOneDevice(serial_number: string): Observable<DeviceBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/device/${serial_number}`, { headers })
      .pipe(
        map((response) => {
          let deviceBDA: DeviceBDA = response.data[0];

          // Formateo de las fechas
          deviceBDA.created_at = moment(deviceBDA.created_at).format('DD/MM/YYYY HH:mm:ss');
          deviceBDA.updated_at = moment(deviceBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');
          if (deviceBDA.deleted_at)
            deviceBDA.deleted_at = moment(deviceBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');

          // Traducción de los atributos type y status
          // userBDA.type = this.TYPES_USER[userBDA.type] || "";

          return deviceBDA;
        })
      );
  }

  createDevice(device: DeviceBDA): Observable<DeviceBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<SuccessResponse>(`${this.url}/device`, device, { headers })
      .pipe(
        map((response)=> response.data[0])
      );
  }

  updateDevice(serial_number: string, device: DeviceBDA): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.patch<SuccessResponse>(`${this.url}/device/${serial_number}`, device, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  deleteDevice(serial_number: string): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.delete<SuccessResponse>(`${this.url}/device/${serial_number}`, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  // Gateway

  createGateway(gateway: GatewayBDA): Observable<GatewayBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<SuccessResponse>(`${this.url}/gateway`, gateway, { headers })
      .pipe(
        map((response)=> response.data[0])
      );
  }

  getAllClientGateway(client_code: string){
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams().set("withAssignedDevices", 1);

    return this.http.get<SuccessResponse>(`${this.url}/gateway/all/${client_code}`, { headers, params })
      .pipe(
        map((response) => {
          response.data.map((value: GatewayBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if (value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type y status
            value.type = this.TYPES_GATEWAY[value.type] || "";
            value.status = this.STATUS[value.status] || "";
            value.mode = this.MODE_GATEWAY[value.mode] || "";

            return value;
          })
          return response;
        })
      );
  }

  getAllGatewayDevices(gateway_code: string): Observable<DeviceBDA[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/gateway/all/${gateway_code}/device`, { headers })
      .pipe(
        map((response) => {
          response.data.map((value: DeviceBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if (value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type, status y mode
            value.type = this.TYPES_DEVICE[value.type] || "";
            value.status = this.STATUS[value.status] || "";
            // value.mode = this.MODE_GATEWAY[value.mode] || "";

            return value;
          })
          return response.data;
        })
      );
  }

  getOneGateway(gateway_code: string): Observable<GatewayBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/gateway/${gateway_code}`, { headers })
      .pipe(
        map((response) => {
          let gatewayBDA: GatewayBDA = response.data[0];

          // Formateo de las fechas
          gatewayBDA.created_at = moment(gatewayBDA.created_at).format('DD/MM/YYYY HH:mm:ss');
          gatewayBDA.updated_at = moment(gatewayBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');
          if (gatewayBDA.deleted_at)
            gatewayBDA.deleted_at = moment(gatewayBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');

          // Traducción de los atributos type, status y mode
          // gatewayBDA.type = this.TYPES_GATEWAY[gatewayBDA.type] || "";
          // gatewayBDA.status = this.STATUS[gatewayBDA.status] || "";
          // gatewayBDA.mode = this.MODE_GATEWAY[gatewayBDA.mode] || "";

          return gatewayBDA;
        })
      );
  }

  updateGateway(code: string, user: GatewayBDA): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.patch<SuccessResponse>(`${this.url}/gateway/${code}`, user, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  deleteGateway(code: string): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.delete<SuccessResponse>(`${this.url}/gateway/${code}`, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  // User

  createUser(device: UserBDA): Observable<DeviceBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<SuccessResponse>(`${this.url}/user`, device, { headers })
      .pipe(
        map((response)=> response.data[0])
      );
  }

  getOneUser(user_code: string): Observable<UserBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/user/${user_code}`, { headers })
      .pipe(
        map((response) => {
          let userBDA: UserBDA = response.data[0];

          // Formateo de las fechas
          userBDA.created_at = moment(userBDA.created_at).format('DD/MM/YYYY HH:mm:ss');
          userBDA.updated_at = moment(userBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');
          if (userBDA.deleted_at)
            userBDA.deleted_at = moment(userBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');

          // Traducción de los atributos type y status
          // userBDA.type = this.TYPES_USER[userBDA.type] || "";

          return userBDA;
        })
      );
  }

  getAllUserDevices(user_code: string): Observable<DeviceBDA[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/user/${user_code}/device`, { headers })
      .pipe(
        map((response) => {
          const deviceBDA: DeviceBDA[] = response.data;

          response.data.map((value: DeviceBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if (value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type y status
            value.type = this.TYPES_DEVICE[value.type] || "";
            value.status = this.STATUS[value.status] || "";

            return value;
          })

          return deviceBDA;
        })
      );
  }

  getAllClientUsers(client_code: string): Observable<UserBDA[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams().set("withAssignedDevices", 1);

    return this.http.get<SuccessResponse>(`${this.url}/user/all/${client_code}`, { headers, params })
      .pipe(
        map((response) => {
          const deviceBDA: UserBDA[] = response.data;

          response.data.map((value: UserBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if (value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type y status
            value.type = this.TYPES_USER[value.type] || "";

            return value;
          })

          return deviceBDA;
        })
      );
  }

  updateUser(code: string, user: UserBDA): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.patch<SuccessResponse>(`${this.url}/user/${code}`, user, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  deleteUser(code: string): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.delete<SuccessResponse>(`${this.url}/user/${code}`, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  // Contact

  createContact(contact: ContactBDA): Observable<DeviceBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<SuccessResponse>(`${this.url}/contact`, contact, { headers })
      .pipe(
        map((response)=> response.data[0])
      );
  }

  getAllClientContacts(client_code: string): Observable<ContactBDA[]> {
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/contact/all/${client_code}`, { headers })
      .pipe(
        map((response) => {
          const contactBDA: ContactBDA[] = response.data;

          response.data.map((value: ContactBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if (value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type y status
            value.type = this.TYPES[value.type] || "";

            return value;
          })

          return contactBDA;
        })
      );
  }

  getOneContact(user_code: string): Observable<ContactBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/contact/${user_code}`, { headers })
      .pipe(
        map((response) => {
          let contactBDA: ContactBDA = response.data[0];

          // Formateo de las fechas
          contactBDA.created_at = moment(contactBDA.created_at).format('DD/MM/YYYY HH:mm:ss');
          contactBDA.updated_at = moment(contactBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');
          if (contactBDA.deleted_at)
            contactBDA.deleted_at = moment(contactBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');

          // Traducción de los atributos type y status
          // userBDA.type = this.TYPES_USER[userBDA.type] || "";

          return contactBDA;
        })
      );
  }

  updateContact(code: string, contact: ContactBDA): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.patch<SuccessResponse>(`${this.url}/contact/${code}`, contact, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  deleteContact(code: string): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.delete<SuccessResponse>(`${this.url}/contact/${code}`, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  // Group
  createGroup(group: GroupBDA): Observable<GroupBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<SuccessResponse>(`${this.url}/group`, group, { headers })
      .pipe(
        map((response)=> response.data[0])
      );
  }

  getAllClientGroups(): Observable<GroupBDA[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams().set("withAssignedDevices", 1);

    return this.http.get<SuccessResponse>(`${this.url}/group/all/client/${this.userActive.client_code}`, { headers, params })
      .pipe(
        map((response)=> {
          const groupsBDA: GroupBDA[] = response.data;

          response.data.map((value: GroupBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if (value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type y status
            value.type = this.TYPES[value.type] || "";
            // console.log(value)
            return value;
          })

          return groupsBDA;
        })
      );
  }

  getAllGroupDevices(group_code: string): Observable<DeviceBDA[]>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/group/all/device/${group_code}`, { headers })
      .pipe(
        map((response)=> {
          const groupsBDA: DeviceBDA[] = response.data;

          response.data.map((value: DeviceBDA) => {
            // Formateo de las fechas
            value.created_at = moment(value.created_at).format('DD/MM/YYYY HH:mm:ss');
            value.updated_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');
            if (value.deleted_at)
              value.deleted_at = moment(value.updated_at).format('DD/MM/YYYY HH:mm:ss');

            // Traducción de los atributos type y status
            value.type = this.TYPES_DEVICE[value.type] || "";
            value.status = this.STATUS[value.status] || "";

            return value;
          })

          return groupsBDA;
        })
      );
  }

  getOneGroup(group_code: string): Observable<GroupBDA>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<SuccessResponse>(`${this.url}/group/${group_code}`, { headers })
      .pipe(
        map((response) => {
          let groupBDA: GroupBDA = response.data[0];

          // Formateo de las fechas
          groupBDA.created_at = moment(groupBDA.created_at).format('DD/MM/YYYY HH:mm:ss');
          groupBDA.updated_at = moment(groupBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');
          if (groupBDA.deleted_at)
            groupBDA.deleted_at = moment(groupBDA.updated_at).format('DD/MM/YYYY HH:mm:ss');

          // Traducción de los atributos type y status
          // userBDA.type = this.TYPES_USER[userBDA.type] || "";

          return groupBDA;
        })
      );
  }

  updateGroup(code: string, device: GroupBDA): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.patch<SuccessResponse>(`${this.url}/group/${code}`, device, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  deleteGroup(code: string): Observable<boolean>{
    const token = JSON.parse(localStorage.getItem("auth_app_token")).value;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.delete<SuccessResponse>(`${this.url}/group/${code}`, { headers })
      .pipe(
        map((response)=> {
          return response.success;
        }),
        catchError((err)=>{
          console.log(err);
          return of(false);
        })
      );
  }

  get userActive(): UserBDTR {
    return JSON.parse(localStorage.getItem("userActive"));
  }
}
