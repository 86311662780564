<div class="container border rounded shadow p-5 bg-light bg-gradient">

<h1 id="title" class="title">{{'auth.request_password.title' | translate}}</h1>

<div *ngIf="!emailIsCorrect">
  <p class="sub-title">{{'auth.request_password.description1' | translate}}</p>
<nb-alert *ngIf="errorEmail" outline="danger" role="alert">
  <p class="alert-title"><b>{{'auth.general.error' | translate}}</b></p>
  <ul class="alert-message-list">
    <li class="alert-message">{{'auth.general.fail' | translate}}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="errorPassword" outline="danger" role="alert">
  <p class="alert-title"><b>{{'auth.general.error' | translate}}</b></p>
  <ul class="alert-message-list">
    <li class="alert-message">{{'auth.request_password.password_error' | translate}}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="submitEmail()" #requestPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">{{'auth.general.username' | translate}}:</label>
    <input nbInput [(ngModel)]="user.email" #email="ngModel" id="input-email" name="email" pattern=".+@.+\..+"
       autofocus fullWidth fieldSize="large"
      [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        {{'auth.general.username_required' | translate}}
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        {{'auth.general.username_email' | translate}}
      </p>
    </ng-container>
  </div>

  <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !requestPassForm.valid"
    [class.btn-pulse]="submitted">
    {{'auth.request_password.button1' | translate}}
  </button>
</form>
</div>

<form *ngIf="emailIsCorrect" [formGroup]="formRequest" (ngSubmit)="requestPass()">

  <p class="sub-title">{{'auth.request_password.description2' | translate}} <b>{{user.email}}</b></p>

    <nb-alert *ngIf="errorPassword" outline="danger" role="alert">
      <p class="alert-title"><b>{{'auth.general.error' | translate}}</b></p>
      <ul class="alert-message-list">
        <li class="alert-message">{{errors}}</li>
      </ul>
    </nb-alert>

  <nb-alert *ngIf="passwordRequest" outline="success" role="alert">
    <p class="alert-title"><b>{{'auth.request_password.ok' | translate}}</b></p>
    <ul class="alert-message-list">
      <li class="alert-message">{{'auth.request_password.ok_description' | translate}} {{user.email}}</li>
    </ul>
  </nb-alert>

  <label class="label">{{'auth.request_password.new_password' | translate}}:</label>
  <input type="password" [ngClass]="{'mb-4': marginInput}" nbInput fullWidth fieldSize="large" formControlName="newPassword">

  <ng-container>
    <p class="caption status-danger"  *ngIf="isValidField('newPassword', 'required')">
      {{'auth.general.username_required' | translate}}
    </p>
    <p class="caption status-danger"  *ngIf="isValidField('newPassword', 'minlength')">
      {{'auth.general.password_minLength1' | translate}} 6 {{'auth.general.password_minLength2' | translate}}
    </p>
  </ng-container>

  <button nbButton fullWidth status="primary" size="large" type="submit"
   [ngClass]="{'mt-4': !formRequest.invalid}" [disabled]="formRequest.invalid">
    {{'auth.request_password.button' | translate}}
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">{{'auth.request_password.back_login' | translate}}</a></p>
  <!-- <p><a routerLink="../register" class="text-link">Ir al Registro</a></p> -->
</section>
</div>
