import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';


import { NbAuthService } from '@nebular/auth';
import { map, tap } from 'rxjs/operators';

const loginGuard = () => {
  const nbAuthService= inject(NbAuthService);
  const router = inject(Router)

  return nbAuthService.isAuthenticated().pipe(
    map(result => !result),
    tap((result)=> {
      if(!result){
        router.navigateByUrl('/pages');
      }
    })
  );
}

export const LoginGuardActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return loginGuard();
}

export const LoginGuardChild: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return loginGuard();
}

