/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocalDataSource, Ng2SmartTableModule } from 'ng2-smart-table';

@Component({
  selector: 'app-smart-table',
  standalone: true,
  imports: [
    CommonModule,
    Ng2SmartTableModule
  ],
  template: `<ng2-smart-table [settings]="settings" [source]="source" ></ng2-smart-table>`,
  styleUrls: ['./smart-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartTableComponent {
  @Input() settings: any;
  @Input() data: any[];
  source: LocalDataSource = new LocalDataSource();

  constructor(){
    this.source.load(this.data);
  }
}
