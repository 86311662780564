import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { NgxAuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';


import { NbAuthModule } from "@nebular/auth";
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
  NbLayoutModule,
  NbCardModule,
  NbIconModule,
  NbSelectModule,
  NbFormFieldModule,
} from "@nebular/theme";
import { CustomAuthComponent } from './custom-auth/custom-auth.component';
import { CustomRegisterComponent } from './custom-register/custom-register.component';
import { CustomRequestPasswordComponent } from './custom-request-password/custom-request-password.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    LoginComponent, 
    CustomAuthComponent, 
    CustomRegisterComponent, 
    CustomRequestPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbAuthModule,
    NbLayoutModule,
    NbCardModule,
    NbIconModule,
    NbSelectModule,
    NbFormFieldModule,
    NgxAuthRoutingModule,
    ReactiveFormsModule,
    TranslateModule
  ],
})
export class AuthModule {}
