import { Component, OnInit } from '@angular/core';

import { NbAuthComponent } from '@nebular/auth';

@Component({
  selector: "ngx-custom-auth",
  templateUrl: "./custom-auth.component.html",
  styleUrls: ["./SCSS/custom-auth.component.scss"],
})
export class CustomAuthComponent extends NbAuthComponent {}
